<template>
  <div class="row m-0">
    <div class="container mt-5">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5 class=" mt-1 mb-0">
                SLA Configuration
              </h5>
              <button
                class="btn btn-sm btn-primary"
                @click="addNewClickHandler"
              >
                Add new
              </button>
            </div>
            <div
              class="card-body p-0"
              style="height: 50em"
            >
              <ag-grid-vue
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="rowData"
                class="ag-theme-alpine w-100 h-100 slaEditModal"
              />
            </div>
          </div>
        </div>
      </div>
      <SlaEditModal
        ref="slaEditModal"
        :viewType="modalViewType"
      />
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapActions, mapGetters } from 'vuex';
import SlaEditModal from './SlaEditModal';
import { modalViewTypes } from '@/components/constants';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { slaGreedColumns } from './constants';

export default {
  components: {
    AgGridVue,
    SlaEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: modalViewTypes.edit
    };
  },
  computed: {
    ...mapGetters({
      rowData: 'sla/slaConfigItems'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return slaGreedColumns({
        rowClickHandler: this.rowClickHandler
      });
    }
  },
  async mounted() {
    await this.fetchSlaConfigItems();
  },
  methods: {
    ...mapActions({
      fetchSlaConfigItems: 'sla/fetchSlaConfigItems'
    }),
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.slaEditModal.initModal(itemId);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.slaEditModal.initModal();
    }
  }
};
</script>
